import React, {useEffect, useState} from "react";
import Seo from "../components/seo"
import Layout from "../components/layout";
import queryString from 'query-string';
import {CompactButton} from "../components/button";
import { navigate } from "gatsby";
import * as R from 'ramda';

const Login = () => {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    // const { state: authState, login } = useAuth();
    const query =
        typeof window !== 'undefined' ?
            queryString.parse(window.location.search) : {};
    const callback: string = query?.callback?.toString() ?? "";

    // useEffect(() => {
    //     if (!R.isNil(authState?.token)) {
    //         if (!R.isNil(callback)) {
    //             navigate(callback);
    //         } else {
    //             navigate('/profile');
    //         }
    //     }
    // }, [authState]);
    //
    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
            }}>
                <div
                >
                    <h5>登入</h5>
                    <form>
                        <label>
                            <p>Email</p>
                            <input type="text" value={email} onChange={(e) => {
                                setEmail(e.target.value);
                            }}/>
                        </label>
                        <label>
                            <p>密碼</p>
                            <input type="password" value={password} onChange={(e) => {
                                setPassword(e.target.value);
                            }}/>
                        </label>
                        {
                            // authState?.errorMessage && (
                            //     <div
                            //         style={{
                            //             color: "red",
                            //             fontSize: "0.8rem",
                            //         }}
                            //     >
                            //
                            //         {authState.errorMessage}
                            //     </div>
                            // )
                        }

                        <div style={{ height: 16}} />
                        <CompactButton onClick={async () => {
                            // await login(email, password);
                        }}>
                            登入
                        </CompactButton>
                        <div style={{ height: 16}} />
                        {/*<CompactButton onClick={() => {*/}

                        {/*}}>*/}
                        {/*    Google 登入*/}
                        {/*</CompactButton>*/}
                        <div style={{ height: 16}} />
                        <CompactButton onClick={() => {
                            navigate(`/register${callback ? `?callback=${callback}` : ""}`);
                        }}>
                            註冊
                        </CompactButton>
                    </form>
                </div>
            </div>
        </div>
    );
}
export  default  Login;
